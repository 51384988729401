import axios from "axios";
import {AUTH_LOCAL_STORAGE_KEY} from "../modules/auth/core/AuthHelpers";

const API_URL = process.env.REACT_APP_PUBLIC_API_URL || "http://localhost:3000";

const config = {
  baseURL: API_URL,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
  },
};

const axiosConfig = axios.create(config);

axiosConfig.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (token) {
      const _token = JSON.parse(token);
      config.headers["Authorization"] = `Bearer ${_token.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosConfig;
