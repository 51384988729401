import {useIntl} from "react-intl";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import clsx from "clsx";
import {FC, useEffect} from "react";
import {MenuInnerWithSub} from "./MenuInnerWithSub";
import {MenuItem} from "./MenuItem";

export function MenuInner() {
  const intl = useIntl();
  const navigate = useNavigate();

  return <></>;
}
