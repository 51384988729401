import {lazy, FC, Suspense} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import {MasterLayout} from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import {DashboardWrapper} from "../pages/dashboard/DashboardWrapper";
import {getCSSVariableValue} from "../../_metronic/assets/ts/_utils";
import {WithChildren} from "../../_metronic/helpers";
import ReportRoutes from "../modules/reports/Routes";

const PrivateRoutes = () => {
  const FirmRoutes = lazy(() => import("../modules/firms/Routes"));
  const LeadRoutes = lazy(() => import("../modules/leads/Routes"));
  const SiteVisitRoutes = lazy(() => import("../modules/siteVisit/Routes"));
  const ProjectRoutes = lazy(() => import("../modules/projects/Routes"));
  const PurchaseRoutes = lazy(() => import("../modules/purchases/Routes"));
  const SaleRoutes = lazy(() => import("../modules/sales/Routes"));
  const LayoutRoutes = lazy(() => import("../modules/layout/Routes"));
  const ExpenseRoutes = lazy(() => import("../modules/expense/Routes"));
  const SoftwareRoutes = lazy(() => import("../modules/software/Routes"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='lead/*'
          element={
            <SuspensedView>
              <LeadRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='siteVisit/*'
          element={
            <SuspensedView>
              <SiteVisitRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='firm/*'
          element={
            <SuspensedView>
              <FirmRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='project/*'
          element={
            <SuspensedView>
              <ProjectRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='layout/*'
          element={
            <SuspensedView>
              <LayoutRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='purchase/*'
          element={
            <SuspensedView>
              <PurchaseRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='sale/*'
          element={
            <SuspensedView>
              <SaleRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='report/*'
          element={
            <SuspensedView>
              <ReportRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='expense/*'
          element={
            <SuspensedView>
              <ExpenseRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='software/*'
          element={
            <SuspensedView>
              <SoftwareRoutes />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRoutes};
