import axiosConfig from "../../../utils/axiosConfig";

export const getAllFirms = async () => {
  const {data, status} = await axiosConfig.get("/firms");
  return {data, status};
};

export const getFirmById = async (id) => {
  const {data, status} = await axiosConfig.get(`/firms/${id}`);
  return {data, status};
};

export const saveFirm = async (firm) => {
  const _formData = new FormData();
  Object.keys(firm).forEach((key) => {
    _formData.append(key, firm[key]);
  });

  const {data, status} = await axiosConfig.post("/firms", _formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return {data, status};
};

export const updateFirm = async (firm) => {
  let _formData = undefined;
  let headers = {};
  delete firm["preview_url"];
  if (firm["image_url"] instanceof File) {
    _formData = new FormData();
    headers["Content-Type"] = "multipart/form-data";
    Object.keys(firm).forEach((key) => {
      if (key !== "id") {
        _formData.append(key, firm[key]);
      }
    });
    _formData.append("_method", "PUT");
  } else {
    _formData = {...firm};
    _formData["_method"] = "PUT";
    delete _formData["image_url"];
  }

  const {data, status} = await axiosConfig.post(`/firms/${firm.id}`, _formData, {
    headers: {
      ...headers,
    },
  });
  return {data, status};
};

export const deleteFirm = async (id) => {
  const {data, status} = await axiosConfig.delete(`/firms/${id}`);
  return {data, status};
};

export const deleteFirms = async (ids) => {
  const {data, status} = await axiosConfig.delete(`/firms`, {ids});
  return {data, status};
};

export const findFirms = async (queryParams) => {
  const {data, status} = await axiosConfig.get("/firms", {queryParams});
  return {data, status};
};

export const saveFirmBank = async (requestData) => {
  const {data, status} = await axiosConfig.post("/firms/banks", requestData);
  return {data, status};
};
