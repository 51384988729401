import axiosConfig from "../../../utils/axiosConfig";

export const getAllProjects = async () => {
  const {data, status} = await axiosConfig.get("/projects");
  return {data, status};
};

export const getProjectById = async (id) => {
  const {data, status} = await axiosConfig.get(`/projects/${id}`);
  return {data, status};
};

export const getAllProjectsByFirmId = async (id) => {
  const {data, status} = await axiosConfig.post(`/projects/filters`, {firm_id: id});
  return {data, status};
};

export const getAllBanksByFirmId = async (id) => {
  const {data, status} = await axiosConfig.post(`/firms/banks/filters`, {firm_id: id});
  return {data, status};
};

export const saveProject = async (project) => {
  const _formData = new FormData();
  Object.keys(project).forEach((key) => {
    if (key === "project_category") {
      // const cats = project[key].map((cat) => cat.value);
      _formData.append(key, JSON.stringify(project[key]));
    } else {
      _formData.append(key, project[key]);
    }
  });

  const {data, status} = await axiosConfig.post("/projects", _formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return {data, status};
};

export const updateProject = async (project) => {
  let _formData = undefined;
  let headers = {};
  delete project["preview_url"];
  if (project["image_url"] instanceof File) {
    _formData = new FormData();
    headers["Content-Type"] = "multipart/form-data";
    Object.keys(project).forEach((key) => {
      if (key !== "id") {
        if (key === "project_category") {
          _formData.append(key, JSON.stringify(project[key]));
        } else {
          _formData.append(key, project[key]);
        }
      }
    });
    _formData.append("_method", "PUT");
  } else {
    _formData = {};
    Object.keys(project).forEach((key) => {
      if (key !== "id") {
        if (key === "project_category") {
          _formData[key] = JSON.stringify(project[key]);
        } else {
          _formData[key] = project[key];
        }
      }
    });
    _formData["_method"] = "PUT";
    delete _formData["image_url"];
  }
  // for (let [key, value] of _formData.entries()) {
  //   console.log(key, value);
  // }

  const {data, status} = await axiosConfig.post(`/projects/${project.id}`, _formData, {
    headers: {
      ...headers,
    },
  });
  return {data, status};
};

export const deleteProject = async (id) => {
  const {data, status} = await axiosConfig.delete(`/projects/${id}`);
  return {data, status};
};

export const deleteProjects = async (ids) => {
  const {data, status} = await axiosConfig.delete(`/projects`, {ids});
  return {data, status};
};

export const findProjects = async (queryParams) => {
  const {data, status} = await axiosConfig.get("/projects", {queryParams});
  return {data, status};
};
