import React, {useEffect, useState} from "react";
import {useSaleParty} from "../../sales/hooks/useSaleParty";
import {getAllFirms} from "../../firms/core/_requests";
import {getAllProjectsByFirmId} from "../../projects/core/_requests";
import {getSaleReport, getSaleReportExportPdf} from "../core/_requests";
import {getSaleParties} from "../../sales/core/_partyRequests";
import {currency} from "../../../utils/currency";

const SaleReport = (props) => {
  const [firms, setFirms] = useState([]);
  const [projects, setProjects] = useState([]);
  const [saleParties, setSaleParties] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedSaleParty, setSelectedSaleParty] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const callFirms = async () => {
    const response = await getAllFirms();
    setFirms(response.data);
  };

  const callProjectsByFirmId = async (id) => {
    const response = await getAllProjectsByFirmId(id);
    setProjects(response.data);
  };

  const callSaleParty = async () => {
    const response = await getSaleParties();
    setSaleParties(response.data);
  };

  const getReport = async () => {
    // Call API to get report
    const obj = {
      firm_id: selectedFirm ? parseInt(selectedFirm, 10) : undefined,
      project_id: selectedProject ? parseInt(selectedProject, 10) : undefined,
      buyer_id: selectedSaleParty ? parseInt(selectedSaleParty, 10) : undefined,
      from_date: fromDate || undefined,
      to_date: toDate || undefined,
    };

    const response = await getSaleReport(obj);
    setTableData(response.data);
  };

  const exportToPdf = async () => {
    // Call API to get report
    const obj = {
      firm_id: selectedFirm ? parseInt(selectedFirm, 10) : undefined,
      project_id: selectedProject ? parseInt(selectedProject, 10) : undefined,
      buyer_id: selectedSaleParty ? parseInt(selectedSaleParty, 10) : undefined,
      from_date: fromDate || undefined,
      to_date: toDate || undefined,
    };

    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("target", "_blank");
    form.setAttribute("action", "https://api.estatepro.live/api/report/sale/pdf");

    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", obj[key]);
        form.appendChild(hiddenField);
      }
    });

    // Append the form to the document body and submit it
    document.body.appendChild(form);
    form.submit();
  };

  const exportToExcel = async () => {
    // Call API to get report
    const obj = {
      firm_id: selectedFirm ? parseInt(selectedFirm, 10) : undefined,
      project_id: selectedProject ? parseInt(selectedProject, 10) : undefined,
      seller_id: selectedSaleParty ? parseInt(selectedSaleParty, 10) : undefined,
      from_date: fromDate || undefined,
      to_date: toDate || undefined,
    };

    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("target", "_blank");
    form.setAttribute("action", "https://api.estatepro.live/api/report/sale/excel");

    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", obj[key]);
        form.appendChild(hiddenField);
      }
    });

    // Append the form to the document body and submit it
    document.body.appendChild(form);
    form.submit();
  };

  useEffect(() => {
    callFirms();
    callSaleParty();
  }, []);

  useEffect(() => {
    if (selectedFirm != null) {
      callProjectsByFirmId(selectedFirm);
    }
  }, [selectedFirm]);

  return (
    <div className='card'>
      <div className='card-header flex-wrap border-0 pt-6 pb-0'>
        <div className='card-title'>
          <h3 className='card-label'>Sale Report</h3>
        </div>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-warning me-3'
            onClick={() => {
              exportToPdf();
            }}
          >
            Export to PDF
          </button>
          <button type='button' className='btn btn-sm btn-primary' onClick={exportToExcel}>
            Export to Excel
          </button>
        </div>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-md-3'>
            <div className='form-group'>
              <label className='text-dark font-weight-bold mb-2'>Firm</label>
              <select
                className='form-select form-select-sm'
                onChange={(e) => setSelectedFirm(e.target.value)}
              >
                <option value={""}> ALL </option>
                {firms.map((firm) => {
                  return (
                    <option key={firm.id} value={firm.id}>
                      {firm.disp_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className='col-md-2'>
            <div className='form-group'>
              <label className='text-dark font-weight-bold mb-2'>Project</label>
              <select
                className='form-select form-select-sm'
                onChange={(e) => setSelectedProject(e.target.value)}
              >
                <option value={""}> ALL </option>
                {projects.map((project) => {
                  return (
                    <option key={project.id} value={project.id}>
                      {project.disp_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='form-group'>
              <label className='text-dark font-weight-bold mb-2'>Sale Party</label>
              <select
                className='form-select form-select-sm'
                onChange={(e) => setSelectedSaleParty(e.target.value)}
              >
                <option value={""}> ALL </option>
                {saleParties.map((pp) => {
                  return (
                    <option key={pp.id} value={pp.id}>
                      {pp.party_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className='col-md-3 d-flex'>
            <div className='form-group'>
              <label className='text-dark font-weight-bold mb-2'>Start Date</label>
              <input
                type='date'
                className='form-control form-control-sm'
                onChange={(e) => setFromDate(e.target.value)}
                value={fromDate}
              />
            </div>
            <div className='form-group'>
              <label className='text-dark font-weight-bold mb-2'>End Date</label>
              <input
                type='date'
                className='form-control form-control-sm'
                onChange={(e) => setToDate(e.target.value)}
                value={toDate}
              />
            </div>
          </div>
          <div className='col-md-1'>
            <div className='form-group'>
              <label className='text-dark font-weight-bold mb-2'>
                <br />
                <br />
              </label>
              <button
                type='button'
                className='btn btn-sm btn-primary btn-icon mt-8'
                onClick={() => {
                  getReport();
                }}
              >
                <i className='fas fa-search'></i>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-md-12'>
            <div className='table-responsive'>
              <table className='table table-bordered table-hover'>
                <thead>
                  <tr>
                    <th>Sl. No.</th>
                    <th>Firm Name</th>
                    <th>Project Name</th>
                    <th>Party Name</th>
                    <th>Invoice Date</th>
                    <th>Invoice No.</th>
                    <th>Without GST</th>
                    <th>CGST</th>
                    <th>SGST</th>
                    <th>IGST</th>
                    <th>Invoice Total</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td className='text-nowrap'>{index + 1}</td>
                        <td className='text-nowrap'>{row.firm?.disp_name}</td>
                        <td className='text-nowrap'>{row.project?.disp_name}</td>
                        <td className='text-nowrap'>
                          {row.unit_allocate?.sale_party?.party_name || ""}
                        </td>
                        <td className='text-nowrap'>{row.document_date}</td>
                        <td className='text-nowrap'>{row.firm_document_number}</td>
                        <td className='text-nowrap text-end'>{currency(row.assessable_value)}</td>
                        <td className='text-nowrap text-end'>{currency(row.total_cgst)}</td>
                        <td className='text-nowrap text-end'>{currency(row.total_sgst)}</td>
                        <td className='text-nowrap text-end'>{currency(row.total_igst)}</td>
                        <td className='text-nowrap text-end'>
                          {currency(row.total_invoice_value)}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={6}></td>
                    <td className='text-end fw-bold'>
                      {currency(
                        tableData
                          .reduce((acc, row) => {
                            return acc + parseFloat(row.assessable_value);
                          }, 0)
                          .toFixed(2)
                      )}
                    </td>
                    <td className='text-end fw-bold'>
                      {currency(
                        tableData
                          .reduce((acc, row) => {
                            return acc + parseFloat(row.total_cgst);
                          }, 0)
                          .toFixed(2)
                      )}
                    </td>
                    <td className='text-end fw-bold'>
                      {currency(
                        tableData
                          .reduce((acc, row) => {
                            return acc + parseFloat(row.total_sgst);
                          }, 0)
                          .toFixed(2)
                      )}
                    </td>
                    <td className='text-end fw-bold'>
                      {currency(
                        tableData
                          .reduce((acc, row) => {
                            return acc + parseFloat(row.total_igst);
                          }, 0)
                          .toFixed(2)
                      )}
                    </td>
                    <td className='text-end fw-bold'>
                      {currency(
                        tableData
                          .reduce((acc, row) => {
                            return acc + parseFloat(row.total_invoice_value);
                          }, 0)
                          .toFixed(2)
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleReport;
