import {Navigate, Route, Routes, Outlet} from "react-router-dom";
import PurchaseReport from "./containers/PurchaseReport";
import SaleReport from "./containers/SaleReport";
import PaymentList from "./containers/Payment/List";

const ReportRoutes = () => {
  return (
    <Routes>
      <Route path='purchaseReport' element={<PurchaseReport />} />
      <Route path='saleReport' element={<SaleReport />} />
      <Route path='payments' element={<PaymentList />} />
      <Route index element={<Navigate to='/report/purchaseReport' />} />
    </Routes>
  );
};

export default ReportRoutes;
