import axiosConfig from "../../../utils/axiosConfig";

export const getPurchaseReport = async (filters) => {
  const {data, status} = await axiosConfig.post("/report/purchase", filters);
  return {data, status};
};

export const getPurchaseReportExportPdf = async (filters) => {
  const {data, status} = await axiosConfig.post("/report/purchase/pdf", filters);
  return {data, status};
};

export const getSaleReport = async (filters) => {
  const {data, status} = await axiosConfig.post("/report/sale", filters);
  return {data, status};
};

export const getSaleReportExportPdf = async (filters) => {
  const {data, status} = await axiosConfig.post("/report/sale/pdf", filters);
  return {data, status};
};
