import axiosConfig from "../../../utils/axiosConfig";

export const getAllSales = async () => {
  const {data, status} = await axiosConfig.get("/sales");
  return {data, status};
};

export const getItemsById = async (id) => {
  const {data, status} = await axiosConfig.get(`/sales/${id}/items`);
  return {data, status};
};

export const getSaleById = async (id) => {
  const {data, status} = await axiosConfig.get(`/sales/${id}`);
  return {data, status};
};

export const getSalesByFirmId = async (id) => {
  const {data, status} = await axiosConfig.post(`/sales/filters`, {firm_id: id});
  return {data, status};
};

export const getSaleByFilters = async (filters) => {
  // Remove empty filters and null filters
  const _filters = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== null && filters[key] !== "") {
      _filters[key] = filters[key];
    }
  });
  const {data, status} = await axiosConfig.post(`/sales/filters`, _filters);
  return {data, status};
};

export const saveSale = async (sale) => {
  const {data, status} = await axiosConfig.post("/sales", sale);
  return {data, status};
};

export const updateSale = async (sale) => {
  const {data, status} = await axiosConfig.put(`/sales/${sale.id}`, sale);
  return {data, status};
};

export const deleteSale = async (id) => {
  const {data, status} = await axiosConfig.delete(`/sales/${id}`);
  return {data, status};
};

export const deleteSales = async (ids) => {
  const {data, status} = await axiosConfig.delete(`/sales`, {ids});
  return {data, status};
};

export const findSales = async (queryParams) => {
  const {data, status} = await axiosConfig.get("/sales", {queryParams});
  return {data, status};
};

export const getPaymentsBySaleId = async (id) => {
  const {data, status} = await axiosConfig.get(`/sales/${id}/payments`);
  return {data, status};
};

export const savePayment = async (saleId, saveData) => {
  const {data, status} = await axiosConfig.post(`/sales/${saleId}/payments`, saveData);
  return {data, status};
};

export const getSaleAgreementData = async (saleId) => {
  const {data, status} = await axiosConfig.get(`/sales/${saleId}/saleAgreementData`);
  return {data, status};
};

export const saveSaleAgreement = async (saveData) => {
  const {data, status} = await axiosConfig.post(`/saleAgreementData`, saveData);
  return {data, status};
};

export const uploadSaleFileUpload = async (saleId, files) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const {data, status} = await axiosConfig.post(`/sales/${saleId}/files`, files, config);
  return {data, status};
};

export const getUploadsBySaleId = async (id) => {
  const {data, status} = await axiosConfig.get(`/sales/${id}/files`, {
    saleId: id,
  });
  return {data, status};
};

export const deleteSaleFileUploadById = async (id) => {
  const {data, status} = await axiosConfig.delete(`/sales/files/${id}`);
  return {data, status};
};

export const getInvoiceNumber = async (id) => {
  const {data, status} = await axiosConfig.get(`/sales/getInvoiceNumber/${id}`);
  return {data, status};
};

export const getAllSaleAllocate = async () => {
  const {data, status} = await axiosConfig.get("/sales/allocate");
  return {data, status};
};

export const getSaleAllocateByFilters = async (filters) => {
  const {data, status} = await axiosConfig.post("/sales/allocate/filters", filters);
  return {data, status};
};

export const saveSaleAllocate = async (saveData) => {
  const {data, status} = await axiosConfig.post("/sales/allocate", saveData);
  return {data, status};
};

export const updateSaleAllocate = async (id, saveData) => {
  const {data, status} = await axiosConfig.put(`/sales/allocate/${id}`, saveData);
  return {data, status};
};

export const getSaleInvoicesByFilters = async (filters) => {
  // Remove empty filters and null filters
  const _filters = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== null && filters[key] !== "") {
      _filters[key] = filters[key];
    }
  });
  const {data, status} = await axiosConfig.post(`/sales/invoice/filters`, _filters);
  return {data, status};
};

export const getSaleInvoicesByFiltersReal = async (filters) => {
  // Remove empty filters and null filters
  const _filters = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== null && filters[key] !== "") {
      _filters[key] = filters[key];
    }
  });
  const {data, status} = await axiosConfig.post(`/sales/invoice/filters/real`, _filters);
  return {data, status};
};

export const getSalePaymentsByFilters = async (filters) => {
  // Remove empty filters and null filters
  const _filters = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== null && filters[key] !== "") {
      _filters[key] = filters[key];
    }
  });
  const {data, status} = await axiosConfig.post(`/sales/payment/filters`, _filters);
  return {data, status};
};

export const saveSaleInvoice = async (saveData) => {
  const {data, status} = await axiosConfig.post("/sales/invoice", saveData);
  return {data, status};
};

export const updateSaleInvoice = async (saveData) => {
  const {data, status} = await axiosConfig.put(`/sales/invoice`, saveData);
  return {data, status};
};

export const getSaleAllocateById = async (id) => {
  const {data, status} = await axiosConfig.get(`/sales/allocate/${id}`);
  return {data, status};
};

export const releaseSaleAllocate = async (id) => {
  const {data, status} = await axiosConfig.post(`/sales/allocate/${id}/release`);
  return {data, status};
};

export const saveDirectSaleInvoice = async (saveData) => {
  const {data, status} = await axiosConfig.post("/sales/invoice/direct", saveData);
  return {data, status};
};

export const updateDirectSaleInvoice = async (saveData) => {
  const {data, status} = await axiosConfig.put(`/sales/invoice/direct`, saveData);
  return {data, status};
};
