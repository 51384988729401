export const currency = (value, decimal = 2) => {
  if (!value) return 0;
  if (typeof value !== "number") value = parseFloat(value);
  let formattedValue = value.toLocaleString("en-IN", {
    maximumFractionDigits: decimal,
    style: "currency",
    currency: "INR",
  });
  // Add a space after the currency symbol
  formattedValue = formattedValue.replace("₹", "₹ ");
  return formattedValue;
};
