import {createContext, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getPurchaseParties} from "../core/_partyRequests";

const PurchasePartyContext = createContext();

const usePurchaseParty = () => {
  return useContext(PurchasePartyContext);
};

const PurchasePartyProvider = ({children}) => {
  const params = useParams();
  const [pageList, setPageList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedParty, setSelectedParty] = useState(null);

  const fetchPageList = async () => {
    setPageList([]);
    setIsLoading(true);
    try {
      const {data, status} = await getPurchaseParties();
      if (status === 200) {
        setPageList(data);
      } else {
        alert("Unable to load the purchase parties");
        setPageList([]);
      }
    } catch (e) {
      alert("Error in loading the purchase parties");
      console.log(e);
      setPageList([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPageList();
  }, []);

  useEffect(() => {
    if (params?.purchasePartyId) {
      setSelectedParty(pageList.find((party) => party.id === parseInt(params.purchasePartyId, 10)));
    }
  }, [params]);

  return (
    <PurchasePartyContext.Provider
      value={{
        pageList,
        fetchPageList,
        isLoading,
        selectedParty,
      }}
    >
      {children}
    </PurchasePartyContext.Provider>
  );
};

export {PurchasePartyProvider, usePurchaseParty};
