/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import {useIntl} from "react-intl";
import {SidebarMenuItemWithSub} from "./SidebarMenuItemWithSub";
import {SidebarMenuItem} from "./SidebarMenuItem";

const SidebarMenuMain = () => {
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: "MENU.DASHBOARD"})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub
        to='/report'
        title={intl.formatMessage({id: "MENU.REPORT"})}
        icon='book'
        fontIcon='bi-book'
      >
        <SidebarMenuItem
          to='/report/purchaseReport'
          title={intl.formatMessage({id: "MENU.PURCHASE"})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/report/saleReport'
          title={intl.formatMessage({id: "MENU.SALE"})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to={`/report/payments`}
          title={`${intl.formatMessage({
            id: "MENU.PAYMENT",
          })}`}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/firm'
        title={intl.formatMessage({id: "MENU.FIRMS"})}
        icon='home'
        fontIcon='bi-home'
      />
      <SidebarMenuItem
        to='/project'
        title={intl.formatMessage({id: "MENU.PROJECTS"})}
        icon='abstract-27'
        fontIcon='bi-abstract-27'
      />
      <SidebarMenuItem
        to='/layout'
        title={intl.formatMessage({id: "MENU.LAYOUT"})}
        icon='abstract-26'
        fontIcon='bi-abstract-26'
      />
      <SidebarMenuItem
        to='/lead'
        title={intl.formatMessage({id: "MENU.LEADS"})}
        icon='address-book'
        fontIcon='bi-address-book'
      />
      <SidebarMenuItem
        to='/siteVisit'
        title={intl.formatMessage({id: "MENU.SITE_VISITS"})}
        icon='address-book'
        fontIcon='bi-address-book'
      />
      <SidebarMenuItemWithSub
        to='/purchase'
        title={intl.formatMessage({id: "MENU.PURCHASES"})}
        icon='purchase'
        fontIcon='bi-person'
      >
        <SidebarMenuItem
          to='/purchase/product'
          title={intl.formatMessage({id: "MENU.PRODUCTS"})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/purchase/party'
          title={intl.formatMessage({id: "MENU.PARTIES"})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to={`/purchase/invoice`}
          title={intl.formatMessage({id: "MENU.NEW"})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to={`/purchase/list`}
          title={intl.formatMessage({id: "MENU.LIST"})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/sale'
        title={intl.formatMessage({id: "MENU.SALES"})}
        icon='some-files'
        fontIcon='bi-person'
      >
        <SidebarMenuItem
          to={`/sale/party`}
          title={intl.formatMessage({id: "MENU.PARTIES"})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to={`/sale/allocate`}
          title={`${intl.formatMessage({id: "MENU.ALLOCATE"})}`}
          hasBullet={true}
        />
        <SidebarMenuItem
          to={`/sale/invoice`}
          title={`${intl.formatMessage({
            id: "MENU.INVOICE",
          })}`}
          hasBullet={true}
        />
        <SidebarMenuItem
          to={`/sale/directInvoice`}
          title={`${intl.formatMessage({
            id: "MENU.DIRECT_INVOICE",
          })}`}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to={`/expense`}
        title={intl.formatMessage({id: "MENU.EXPENSES"})}
        icon='book'
        fontIcon='bi-person'
      />
      {/* <SidebarMenuItemWithSub
        to='/software'
        title={intl.formatMessage({id: "MENU.S_W"})}
        icon='element-11'
        fontIcon='bi-app-indicator'
      >
        <SidebarMenuItem
          to={`/software/backup`}
          title={intl.formatMessage({id: "MENU.BACKUP"})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + "/docs/changelog"}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  );
};

export {SidebarMenuMain};
