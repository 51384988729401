import {createContext, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getSaleParties} from "../core/_partyRequests";

const SalePartyContext = createContext();

const useSaleParty = () => {
  return useContext(SalePartyContext);
};

const SalePartyProvider = ({children}) => {
  const params = useParams();
  const [pageList, setPageList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedParty, setSelectedParty] = useState(null);

  const fetchPageList = async () => {
    setPageList([]);
    setIsLoading(true);
    try {
      const {data, status} = await getSaleParties();
      if (status === 200) {
        setPageList(data);
      } else {
        alert("Unable to load the sale parties");
        setPageList([]);
      }
    } catch (e) {
      alert("Error in loading the sale parties");
      console.log(e);
      setPageList([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPageList();
  }, []);

  useEffect(() => {
    if (params?.salePartyId) {
      setSelectedParty(pageList.find((party) => party.id === parseInt(params.salePartyId, 10)));
    }
  }, [params]);

  return (
    <SalePartyContext.Provider
      value={{
        pageList,
        fetchPageList,
        isLoading,
        selectedParty,
      }}
    >
      {children}
    </SalePartyContext.Provider>
  );
};

export {SalePartyProvider, useSaleParty};
