import axiosConfig from "../../../utils/axiosConfig";

export const getPurchaseParties = async () => {
  const {data, status} = await axiosConfig.get("/purchaseParties");
  return {data, status};
};

export const getPurchasePartyById = async (id) => {
  const {data, status} = await axiosConfig.get(`/purchaseParties/${id}`);
  return {data, status};
};

export const savePurchaseParty = async (formData) => {
  const {data, status} = await axiosConfig.post(`/purchaseParties`, formData);
  return {data, status};
};

export const updatePurchaseParty = async (formData) => {
  const {data, status} = await axiosConfig.put(`/purchaseParties/${formData.id}`, formData);
  return {data, status};
};

export const deletePurchaseParty = async (id) => {
  const {data, status} = await axiosConfig.delete(`/purchaseParties/${id}`);
  return {data, status};
};

export const getPurchasePartyInvoices = async (id) => {
  const {data, status} = await axiosConfig.get(`/purchaseParties/${id}/invoices`);
  return {data, status};
};

export const getPartyNameAlreadyExists = async (name) => {
  const {data, status} = await axiosConfig.get(`/purchaseParties/exists/party_name/${name}`);
  return {data, status};
};

export const getGstAlreadyExists = async (text) => {
  const {data, status} = await axiosConfig.get(`/purchaseParties/exists/gstin/${text}`);
  return {data, status};
};

export const getPanAlreadyExists = async (text) => {
  const {data, status} = await axiosConfig.get(`/purchaseParties/exists/pan/${text}`);
  return {data, status};
};

export const getPurchasePartiesByProductId = async (productId) => {
  const {data, status} = await axiosConfig.get(`/purchaseParties/product/${productId}`);
  return {data, status};
};
