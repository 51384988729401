import axiosConfig from "../../../utils/axiosConfig";

export const getSaleParties = async () => {
  const {data, status} = await axiosConfig.get("/saleParties");
  return {data, status};
};

export const getSalePartyById = async (id) => {
  const {data, status} = await axiosConfig.get(`/saleParties/${id}`);
  return {data, status};
};

export const saveSaleParty = async (formData) => {
  const {data, status} = await axiosConfig.post(`/saleParties`, formData);
  return {data, status};
};

export const updateSaleParty = async (formData) => {
  const {data, status} = await axiosConfig.put(`/saleParties/${formData.id}`, formData);
  return {data, status};
};

export const deleteSaleParty = async (id) => {
  const {data, status} = await axiosConfig.delete(`/saleParties/${id}`);
  return {data, status};
};

export const getSalePartyInvoices = async (id) => {
  const {data, status} = await axiosConfig.get(`/saleParties/${id}/invoices`);
  return {data, status};
};
