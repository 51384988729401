import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {getAllFirms} from "../../../firms/core/_requests";
import {getSalePaymentsByFilters} from "../../../sales/core/_requests";
import moment from "moment";
import {getAllProjectsByFirmId} from "../../../projects/core/_requests";
import {getSaleParties} from "../../../sales/core/_partyRequests";

const PaymentList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [firms, setFirms] = useState([]);
  const [projects, setProjects] = useState([]);
  const [saleParties, setSaleParties] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState(null);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedSaleParty, setSelectedSaleParty] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);

  const callFirms = async () => {
    const response = await getAllFirms();
    setFirms(response.data);
  };

  const callProjectsByFirmId = async (id) => {
    const response = await getAllProjectsByFirmId(id);
    setProjects(response.data);
  };

  const callSaleParty = async () => {
    const response = await getSaleParties();
    setSaleParties(response.data);
  };

  const getReport = async () => {
    // Call API to get report
    const obj = {
      firm_id: selectedFirm ? parseInt(selectedFirm, 10) : undefined,
      project_id: selectedProject ? parseInt(selectedProject, 10) : undefined,
      buyer_id: selectedSaleParty ? parseInt(selectedSaleParty, 10) : undefined,
      from_date: fromDate || undefined,
      to_date: toDate || undefined,
      payment_mode: selectedPaymentMode || undefined,
    };

    const response = await getSalePaymentsByFilters(obj);
    setTableData(response.data);
  };

  useEffect(() => {
    callFirms();
    callProjectsByFirmId();
    callSaleParty();
    getReport();
  }, []);

  useEffect(() => {
    if (selectedFirm != null) {
      callProjectsByFirmId(selectedFirm);
    }
  }, [selectedFirm]);

  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body p-9'>Loading...</div>
      </div>
    );
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header flex-wrap border-0 pt-6 pb-0'>
          <div className='card-title'>
            <h3 className='card-label'>
              Your Payments of All Sales Invoices
              <span className='d-block text-muted pt-2 fs-6'></span>
            </h3>
          </div>
          <div className='card-toolbar'></div>
        </div>
        <div className='card-body'>
          <div className='mb-7'>
            <div className='row mb-3'>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label className='text-dark font-weight-bold mb-2'>Firm</label>
                  <select
                    className='form-select form-select-sm'
                    onChange={(e) => setSelectedFirm(e.target.value)}
                  >
                    <option value={""}> ALL </option>
                    {firms.map((firm) => {
                      return (
                        <option key={firm.id} value={firm.id}>
                          {firm.disp_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label className='text-dark font-weight-bold mb-2'>Project</label>
                  <select
                    className='form-select form-select-sm'
                    onChange={(e) => setSelectedProject(e.target.value)}
                  >
                    <option value={""}> ALL </option>
                    {projects.map((project) => {
                      return (
                        <option key={project.id} value={project.id}>
                          {project.disp_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label className='text-dark font-weight-bold mb-2'>Sale Party</label>
                  <select
                    className='form-select form-select-sm'
                    onChange={(e) => setSelectedSaleParty(e.target.value)}
                  >
                    <option value={""}> ALL </option>
                    {saleParties.map((pp) => {
                      return (
                        <option key={pp.id} value={pp.id}>
                          {pp.party_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <div className='form-group'>
                  <label className='text-dark font-weight-bold mb-2'>Start Date</label>
                  <input
                    type='date'
                    className='form-control form-control-sm'
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate}
                  />
                </div>
              </div>
              <div className='col-md-3'>
                <div className='form-group'>
                  <label className='text-dark font-weight-bold mb-2'>End Date</label>
                  <input
                    type='date'
                    className='form-control form-control-sm'
                    onChange={(e) => setToDate(e.target.value)}
                    value={toDate}
                  />
                </div>
              </div>
              <div className='col-md-3'>
                <div className='form-group'>
                  <label className='text-dark font-weight-bold mb-2'>Select Payment Mode</label>
                  <select
                    className='form-select form-select-sm'
                    onChange={(e) => setSelectedPaymentMode(e.target.value)}
                  >
                    <option value={""}> ALL </option>
                    {["CASH", "CHEQUE", "BANK_TRANSFER", "CREDIT_CARD", "DEBIT_CARD", "UPI"].map(
                      (pp) => {
                        return (
                          <option key={pp} value={pp}>
                            {pp.split("_").join(" ")}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='form-group'>
                  <label className='text-dark font-weight-bold mb-2'>
                    <br />
                    <br />
                  </label>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary mt-8'
                    onClick={() => {
                      getReport();
                    }}
                  >
                    Get List
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='row g-5 g-xl-8'>
            <div className='table-responsive'>
              <table className='table table-striped table-row-bordered gy-5 gs-7'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                    <th className=''>Invoice Number</th>
                    <th className=''>Sales Party</th>
                    <th className=''>Payment Mode</th>
                    <th className=''>Payment Date</th>
                    <th className=''>Payment Details</th>
                    <th className=''>Payment Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((l, k) => {
                    return (
                      <tr key={k}>
                        <td>{l.firm_document_number}</td>
                        <td>{l?.sale_invoice?.sale_party?.party_name}</td>
                        <td>{l.payment_mode.split("_").join(" ")}</td>
                        <td>{moment(l.payment_date).format("DD/MM/YYYY")}</td>
                        <td>{`${l.reference_number}, ${l.bank_name}, ${l.branch_name}`}</td>
                        <td className='text-end'>₹ {l.payment_amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentList;
