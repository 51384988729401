/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from "react";
import {useIntl} from "react-intl";
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import {PageTitle} from "../../../_metronic/layout/core";
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from "../../../_metronic/partials/widgets";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {callVisitorsCustomers} from "./_request";

// const DashboardPage: FC = () => <></>;

const DashboardWrapper = () => {
  const intl = useIntl();
  const [visitorsCustomers, setVisitorsCustomers] = React.useState({
    visitors: [],
    customers: [],
    xAxis: [],
  });

  const getVisitorsCustomers = async () => {
    const response = await callVisitorsCustomers();
    if (response && response.status === 200) {
      setVisitorsCustomers(response.data);
    }
  };

  React.useEffect(() => {
    getVisitorsCustomers();
  }, []);

  const options = {
    title: {
      text: null,
      align: "left",
    },
    subtitle: {
      text: null,
      align: "left",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: "Months",
      },
      categories: [1995, 2000, 2005, 2010, 2015, 2020, 2023],
      tickWidth: 0,
      gridLineWidth: 1,
    },
    yAxis: [
      {
        title: {
          text: null,
        },
      },
    ],
    legend: {
      align: "left",
      verticalAlign: "top",
      borderWidth: 0,
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    series: [
      {
        name: "Visitors",
        data: [16, 361, 1018, 2025, 3192, 4673, 5200],
      },
      {
        name: "Customers",
        data: [160, 3610, 10180, 20250, 31920, 46730, 5200],
      },
    ],
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: "MENU.DASHBOARD"})}</PageTitle>
      <div className={`card`}>
        <div className='card-header border-0'>
          <h3 className='card-title fw-bold text-dark'>Visitors / Customer</h3>
          <div className='card-toolbar'></div>
        </div>
        <div className='card-body pt-2'>
          <div className='d-flex align-items-center mb-7'>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>
      </div>
    </>
  );
};

export {DashboardWrapper};
